







































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { CropName } from "@/store/models/crop";
import { Supply } from "@/store/models/DemandAndSupply";
import {
  CropStore,
  DemandSupplyStore,
  CropVarietyStore,
  UserStore,
  JWTStore,
  MetaStore
} from "@/store/modules";
import DynamicField from "@/views/Admin/DynamicField.vue";
import { MetaInfo } from "vue-meta";
import { eventHub } from "@/utils/eventHub";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";
import { roleChecker } from "@/utils/jwt";
import { localeData } from "moment";
import { Base } from "@/store/API/api";

@Component({
  components: {
    DynamicField
  },
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("subsidyFormDownload")}`,
      meta: [
        { name: "keyword", content: "Sqcc supply list" },
        {
          name: "description",
          content: "List of Supplies of seeds",
          vmid: "supplyList"
        }
      ]
    };
  }
})
export default class SubsidyFormDownload extends Vue {
  downloadFile(url: string, name: string = "") {
    if (url != "") {
      const link = document.createElement("a");
      link.href = Base + url;
      if (name != "") {
        link.setAttribute("download", name);
      }
      document.body.appendChild(link);
      link.click();
    }
  }
}
