


































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { CropName } from "@/store/models/crop";
import { Supply } from "@/store/models/DemandAndSupply";
import { CropStore, DemandSupplyStore, CropVarietyStore, MetaStore } from "@/store/modules";
import DynamicField from "@/views/Admin/DynamicField.vue";
import { MetaInfo } from "vue-meta";
import { convertADtoBS, nepaliDate } from "@/store/modules/dateConverter";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component({
  components: {
    DynamicField
  },
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("supplyList")}`,
      meta: [
        { name: "keyword", content: "Sqcc supply list" },
        {
          name: "description",
          content: "List of Supplies of seeds",
          vmid: "supplyList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  Id: string = "" as any;
  fiscalYearId: number | null = null;
  searchCode: string | null = null;
  pageNum: number = 1;
  supplyCrop: CropName = {} as CropName;
  supply: Supply = {} as Supply;
  language: string = "";
  varietyName: string = "";
  seedType: number = 1;
  qty: string = "";
  clearButton: boolean = false;
  loading: boolean = false;

  get dataList() {
    return DemandSupplyStore.SupplyCluster;
  }

  get FiscalYearList() {
    return MetaStore.FiscalYear;
  }

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne") {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  search() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    query = this.routerReplacer();
    DemandSupplyStore.getAllSupplyCluster(query);
    this.loading = false;
  }

  clearSearch() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    this.fiscalYearId = null;
    this.searchCode = null;
    this.routerReplacer();
    query["type"] = "supply";
    DemandSupplyStore.getAllSupplyCluster(query);
  }

  routerReplacer() {
    let query: any = {};
    if (this.pageNum) {
      query["page"] = String(this.pageNum);
    }
    if (this.fiscalYearId != null) {
      query["fiscalYearId"] = String(this.fiscalYearId);
    }
    if (this.searchCode != null) {
      query["searchCode"] = String(this.searchCode);
    }

    query["type"] = "supply";

    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: query
        })
        .catch(error => {});
    }
    query["lang"] = this.$i18n.locale;
    return query;
  }

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return this.nepaliUnicodeNumber(i);
  }

  nepaliDate(date: string) {
    return nepaliDate(date, this.$i18n.locale);
  }

  thousandSeprator(amount: number) {
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  supplyDetail(id: number) {
    this.$router
      .push({
        name: "SupplyDetail",
        params: { clusterId: String(id) }
      })
      .catch(error => {});
  }

  private async created() {
    this.language = this.$i18n.locale;
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    if (this.$route.query.fiscalYearId) {
      this.fiscalYearId = Number(this.$route.query.fiscalYearId);
    }
    if (this.$route.query.searchCode) {
      this.searchCode = String(this.$route.query.searchCode);
    }
    let query = this.routerReplacer();
    this.loading = true;
    await MetaStore.getAllFiscalYear();
    await DemandSupplyStore.getAllSupplyCluster(query);
    this.loading = false;
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    let query = this.routerReplacer();
    await DemandSupplyStore.getAllSupplyCluster(query);
    this.loading = false;
  }
}
