




























































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Demand } from "@/store/models/DemandAndSupply";
import { CropVarietyStore, CropStore, DemandSupplyStore } from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { convertADtoBS, nepaliDate, nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("supplyDetail")}`,
      meta: [
        { name: "keyword", content: "Sqcc demand list" },
        {
          name: "description",
          content: "List of Demands of seeds",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  cropId: number = 0;
  pageNum: number = 1;
  clusterId: number = 0;
  language: string = "";
  loading: boolean = false;

  get dataList() {
    return DemandSupplyStore.Supplies;
  }

  indexMethod(index: number) {
    return this.nepaliUnicodeNumber(index + 1);
  }

  nepaliDate(date: string) {
    return nepaliDate(date, this.$i18n.locale);
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  thousandSeprator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  toogleExpand(row: any) {
    let $table = this.$refs.dataTable as any;
    $table.toggleRowExpansion(row);
  }

  sold(supply: any, distribution: any, supplyIndex: number, distributionIndex: number) {
    let company = distribution.demandcompany;
    let allocatedquantity = distribution.allocatedquantity;
    let quantity = distribution.quantity;
    let id = distribution.id;
    let group = supply.croptypename;
    let variety = supply.cropvarietyname;
    let seedtype = supply.seedtypename;
    let confirmMessage = `"This will mark the demand of ${company} for ${nepaliUnicodeNumber(
      allocatedquantity
    )}  kg of ${group}'s  ${variety}'s ${seedtype}  seed as sold. This cannot be changed later."`;
    // if (this.$i18n.locale == "ne") {
    //   confirmMessage = `यसले स्थायी रूपमा ${distribution.croptypename} बालीको ${distribution.cropvarietynepname} प्रजातिको आपूर्ति र सबै सम्बन्धित डाटलाई मेटाउनेछ। के तपाईं मेटाउन चाहानुहुन्छ?`;
    // }
    this.$confirm(confirmMessage, `${this.$t("confirm").toString()}?`, {
      confirmButtonText: this.$t("confirm").toString(),
      confirmButtonClass: "el-button--danger",
      cancelButtonText: this.$t("cancel").toString(),
      showClose: false,
      type: "warning"
    })
      .then(async () => {
        this.dataList[supplyIndex].distributionlist[distributionIndex].sold = true;
        DemandSupplyStore.distributionsSold([
          this.dataList[supplyIndex].distributionlist[distributionIndex]
        ]);
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: this.$t("cancelled").toString()
        });
      });
  }

  private async created() {
    this.language = this.$i18n.locale;
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    this.clusterId = Number(this.$route.params.clusterId);
    this.loading = true;
    await DemandSupplyStore.getSupplyByClusterId({
      clusterId: this.clusterId,
      lang: this.$i18n.locale
    });
    this.loading = false;
  }
}
