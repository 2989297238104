var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"adminMain"},[_c('div',{staticClass:"flex spaceBetween adminMain__head verticleCentering"},[_c('div',{staticClass:"flex"},[_c('router-link',{attrs:{"to":{ name: 'CompanyMenu' }}},[_c('el-button',{attrs:{"icon":"el-icon-back","circle":""}})],1),_c('h3',[_vm._v(_vm._s(_vm.$t("subsidyFormDownload")))])],1)]),_c('h5',[_vm._v(_vm._s(_vm.$t("note"))+": "+_vm._s(_vm.$t("subsidyFormDownloadMessage")))]),_c('el-row',{staticClass:"adminMain__body",attrs:{"gutter":10}},[_c('el-col',{staticStyle:{"margin-bottom":"10px"},attrs:{"lg":6,"md":6,"sm":12,"xs":24}},[_c('el-button',{staticClass:"download-form-button",attrs:{"icon":"el-icon-download"},on:{"click":function($event){return _vm.downloadFile('/static/download_documents/ट्यागको विवरण.docx', 'ट्यागको विवरण.docx')}}},[_vm._v(" ट्यागको विवरण ")])],1),_c('el-col',{staticStyle:{"margin-bottom":"10px"},attrs:{"lg":6,"md":6,"sm":12,"xs":24}},[_c('el-button',{staticClass:"download-form-button",attrs:{"icon":"el-icon-download"},on:{"click":function($event){return _vm.downloadFile(
              '/static/download_documents/बीउको सम्पूर्ण विश्लेषण प्रतिवेदन सारांशमा.docx',
              'बीउको सम्पूर्ण विश्लेषण प्रतिवेदन सारांशमा.docx'
            )}}},[_vm._v(" बीउको सम्पूर्ण विश्लेषण प्रतिवेदन सारांशमा ")])],1),_c('el-col',{staticStyle:{"margin-bottom":"10px"},attrs:{"lg":6,"md":6,"sm":12,"xs":24}},[_c('el-button',{staticClass:"download-form-button",attrs:{"icon":"el-icon-download"},on:{"click":function($event){_vm.downloadFile(
              '/static/download_documents/बीउ (प्रजनन् तथा मूल) खरिद बील.docx',
              'बीउ (प्रजनन् तथा मूल) खरिद बील.docx'
            )}}},[_vm._v(" बीउ (प्रजनन् तथा मूल) खरिद बील ")])],1),_c('el-col',{staticStyle:{"margin-bottom":"10px"},attrs:{"lg":6,"md":6,"sm":12,"xs":24}},[_c('el-button',{staticClass:"download-form-button",attrs:{"icon":"el-icon-download"},on:{"click":function($event){_vm.downloadFile(
              '/static/download_documents/बीउ वितरण विवरण (बिल  भर्पाई).docx',
              'बीउ वितरण विवरण (बिल  भर्पाई).docx'
            )}}},[_vm._v(" बीउ वितरण विवरण (बिल भर्पाई) ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }